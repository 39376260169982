<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.9546 3C15.3455 3 18.9092 6.57271 18.9092 10.9748C18.9092 15.3769 15.3455 18.9496 10.9546 18.9496C6.56366 18.9496 3 15.3769 3 10.9748C3 6.57271 6.56366 3 10.9546 3ZM10.9546 17.1774C14.3724 17.1774 17.1415 14.4013 17.1415 10.9748C17.1415 7.54741 14.3724 4.77218 10.9546 4.77218C7.53588 4.77218 4.76769 7.54741 4.76769 10.9748C4.76769 14.4013 7.53588 17.1774 10.9546 17.1774ZM18.454 17.2403L20.9544 19.7462L19.7038 21L17.2042 18.4933L18.454 17.2403Z"
        fill="#8392A9"/>
  </svg>
</template>

<script>
export default {
  name: "IconSearch"
}
</script>
